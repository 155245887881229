import { graphql } from "gatsby"
import React from "react"
import { PageLinks } from "../common/site/page-static-links"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import GuideInformation from "../components/GuideInformation/GuideInformation"
// import GuideSliderCard from "../components/GuideSliderCard/GuideSliderCard"
import ImageCard from "../components/ImageCard/ImageCard"
import Layout from "../components/layout"
import Seo from "../components/seo"

const GuideDetailsTemplate = ({ data }) => {
  const PageData = data?.strapiGuide
  const globalContent =
    data?.allStrapiGlobalContent?.edges?.length > 0 &&
    data?.allStrapiGlobalContent?.edges[0]
  return (
    <Layout popularSearch="Generic pages">
      <div className="layout-padding-top">
        <BreadcrumbModule
          subparentlabel={PageLinks?.guidelabel}
          subparentlink={PageLinks?.guide}
          title={PageData?.title}
          parentlabel={PageLinks?.insightslabel}
          parentlink={PageLinks?.insights}
        />
        {PageData?.guide_modules?.map((module) => {
          return (
            <div key={module.id}>
              {module?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_TEXT_AND_IMAGE" && (
                <GuideInformation
                  guideData={PageData}
                  moduleData={module}
                  globalContent={globalContent}
                />
              )}
              {/* {module?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_SLIDER_MODULE" && (
                <GuideSliderCard data={module} PageData={PageData} />
              )} */}
              {module?.strapi_component === "page-modules.image-with-page" && (
                <ImageCard
                  {...module}
                  slide_count="4"
                  id={PageData.strapi_id}
                  imagetransforms={PageData.imagetransforms}
                />
              )}
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiGuide
  return (
    <Seo
      title={`${PageData.title} ${
        PageData.designation ? ` | ${PageData.designation}` : ""
      }`}
      description={`Get to know about ${PageData.title} here. Contact one of our estate agents for assistance in finding the right property for you.`}
    />
  )
}

export default GuideDetailsTemplate

export const query = graphql`
  query ($page_id: Int) {
    strapiGuide(strapi_id: { eq: $page_id }) {
      ...GuideFragment

      guide_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_TEXT_AND_IMAGE {
          __typename
          id
          list_item {
            id
            content {
              data {
                content
              }
            }
            image {
              url
            }
            strapi_id
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_WITH_PAGE {
          id
          secondary_title
          strapi_component
          primary_title
          bg_color
          choose_spacing
          card_items {
            image {
              url
              alternativeText
            }
            custom_link
            cta_label
            cta_link {
              slug
              strapi_parent {
                slug
              }
            }
          }
        }
      }
    }
    allStrapiGlobalContent {
      edges {
        node {
          guide_team_content {
            data {
              guide_team_content
            }
          }
          guide_team_title
        }
      }
    }
  }
`
